<template>
    <v-main class="mt-12">
        <v-row class="ml-0 mt-0">
            <Highlights />
        </v-row>
        <v-row id="dashboards-graphs-grid" class="ml-0 mt-9">
            <v-col sm="12">
                <v-card flat>
                    <h3>
                        <v-icon class="v-icon-header">mdi-tag-plus-outline</v-icon>
                        {{ $tc('dashboards.fundWallet.label') }}
                    </h3>
                    <CarteiraDoFundo />
                </v-card>
            </v-col>
            <v-col sm="12" md="6">
                <v-card flat>
                    <h3>
                        <v-icon class="v-icon-header">mdi-chart-bar-stacked</v-icon> Vencimentos
                        futuros
                    </h3>
                    <VencimentosFuturo />
                </v-card>
            </v-col>
            <v-col sm="12" md="6">
                <v-card flat>
                    <h3>
                        <v-icon class="v-icon-header">mdi-chart-pie</v-icon>
                        {{ $tc('dashboards.balancePerUF.label') }}
                    </h3>
                    <ConcentracaoSaldoPorUF />
                </v-card>
            </v-col>
            <v-col sm="12" md="6">
                <v-card flat>
                    <h3>
                        <v-icon class="v-icon-header">mdi-tag-plus-outline</v-icon>
                        {{ $tc('dashboards.limitsUsagePerUF.label') }}
                    </h3>
                    <LimitesPorUF />
                </v-card>
            </v-col>
            <v-col sm="12" md="6">
                <v-card flat>
                    <h3>
                        <v-icon class="v-icon-header">mdi-file-search-outline</v-icon>
                        {{ $tc('dashboards.analyticsLists.label') }}
                    </h3>
                    <ListasAnaliticas />
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VencimentosFuturo from '../components/VencimentosFuturo';
import LimitesPorUF from '../components/LimitesPorUF';
import ConcentracaoSaldoPorUF from '../components/ConcentracaoSaldoPorUF';
import CarteiraDoFundo from '../components/CarteiraDoFundo';
import ListasAnaliticas from '../components/ListasAnaliticas';
import Highlights from '../components/Highlights';

export default {
    components: {
        VencimentosFuturo,
        LimitesPorUF,
        ConcentracaoSaldoPorUF,
        CarteiraDoFundo,
        ListasAnaliticas,
        Highlights,
    },
    created() {
        this.resetState();
    },
    methods: {
        ...mapActions('dashboards', {
            resetState: 'resetState',
        }),
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
        }),
    },
    /*
    watch: {
        selectedProduct() {
            console.log('changed');
            //this.resetState();
        },
    },
    */
};
</script>

<style scoped lang="scss">
@import './Main.scss';
</style>

export const top40ClientsExposureHeaders = [
    {
        text: 'Nome',
        sortable: false,
        value: 'clientesQuarentaNomeCliente',
        align: 'left',
    },
    {
        text: 'CPF/CNPJ',
        sortable: false,
        value: 'clientesQuarentaRaiz',
        width: 192,
        align: 'center',
    },
    {
        text: 'Saldo a vencer',
        sortable: false,
        value: 'clientesQuarentaSaldoAvencer',
        align: 'center',
        width: 160,
    },
];

export const overdueClientsHeaders = [
    {
        text: 'Nome/Razão Social',
        sortable: false,
        value: 'clientesAtrasoNomeCliente',
        align: 'left',
    },
    {
        text: 'CPF/CNPJ',
        sortable: false,
        value: 'clientesAtrasoRaiz',
        width: 192,
        align: 'center',
    },
    {
        text: 'Saldo Atraso',
        sortable: false,
        value: 'clientesAtrasoSaldoVencido',
        align: 'center',
        width: 160,
    },
    {
        text: 'Tempo',
        sortable: false,
        value: 'clientesAtrasoDiasAtraso',
        align: 'center',
        width: 69,
    },
];
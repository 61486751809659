<template>
    <section>
        <apexchart
            v-if="balancesPerUF && balancesPerUFRequestStatus === RequestStatusEnum.SUCCESS"
            height="400px"
            :series="series.top.values"
            :options="options.top"
        ></apexchart>

        <v-row v-if="balancesPerUF" align="center" justify="center" class="mt-3 mb-3">
            <v-btn
                v-if="balancesPerUFRequestStatus === RequestStatusEnum.SUCCESS"
                @click="dialog = true"
                color="secondary"
                depressed
                outlined
            >
                {{ $tc('dashboards.balancePerUF.detailed') }}
            </v-btn>
        </v-row>
        
        <Loader
            v-if="balancesPerUFRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />

        <v-alert
            v-if="balancesPerUFRequestStatus.type === RequestStatusEnum.ERROR"
            align="left"
            justify="center"
            dense
            outlined
            type="error"
            class="mt-6 ml-3 mr-3"
        >
            {{ $tc('dashboards.error') }}
            <v-btn depressed color="secondary" class="ml-3" @click="load">
                {{ $tc('defaults.form.reload') }}
            </v-btn>
        </v-alert>

        <v-dialog content-class="modal-default" v-model="dialog">
            <header>
                {{ $tc('dashboards.balancePerUF.modal.title') }}
                <v-icon role="button" title="Fechar" @click="dialog = false" color="altPrimary">
                    mdi-close-thick
                </v-icon>
            </header>

            <v-main>
                <section class="mt-9">
                    <apexchart
                        height="480px"
                        :series="series.all.values"
                        :options="options.all"
                    ></apexchart>
                </section>
            </v-main>
        </v-dialog>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
import { balancesPerUFChartOptions } from './_options';

export default {
    components: {
        Loader,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchBalancesPerUF: 'fetchBalancesPerUF',
        }),
        load() {
            this.fetchBalancesPerUF({
                idProduct: this.selectedProduct.id,
                filters: {},
            });
        },
        resetSeries() {
            this.series = {
                top: { labels: [], values: [] },
                all: { labels: [], values: [] },
            };
        },
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            balancesPerUF: 'balancesPerUF',
            balancesPerUFRequestStatus: 'balancesPerUFRequestStatus',
        }),
        sortedResult() {
            if (!this.balancesPerUF) return [];
            return [...this.balancesPerUF].sort((prev, next) =>
                prev.ufSaldoAvencerValor > next.ufSaldoAvencerValor ? -1 : 1
            );
        },
    },
    watch: {
        balancesPerUF(newValue) {
            this.resetSeries();

            const MAX_ITEMS = 5;

            let othersSum = 0;

            if (!newValue) {
                return false;
            }

            this.sortedResult.forEach((r, index) => {
                this.series.all.labels.push(r.ufSaldoAvencer);
                this.series.all.values.push(r.ufSaldoAvencerValor);
                if (index < MAX_ITEMS) {
                    this.series.top.values.push(r.ufSaldoAvencerValor);
                    this.series.top.labels.push(r.ufSaldoAvencer);
                } else {
                    othersSum += r.ufSaldoAvencerValor;
                }
            });
            this.series.top.values.push(othersSum);
            this.series.top.labels.push('Outros');

            this.options.top.labels = this.series.top.labels;
            this.options.all.labels = this.series.all.labels;
        },
    },
    data() {
        return {
            RequestStatusEnum,
            dialog: false,
            series: {
                top: { labels: [], values: [] },
                all: { labels: [], values: [] },
            },
            options: {
                top: {
                    ...balancesPerUFChartOptions,
                },
                all: {
                    ...balancesPerUFChartOptions,
                },
            },
        };
    },
};
</script>
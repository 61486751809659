<template>
    <section class="row" id="dashboards-highlights-container">
        <v-row class="ml-0">
            <v-col md="3">
                <v-card flat class="v-card-fundwallet">
                    <h3>{{ $tc('dashboards.mainHighlight.title') }}</h3>
                    <h4>{{ $tc('dashboards.mainHighlight.subtitle') }}</h4>
                    <span v-if="balanceDue && balanceDueRequestStatus === RequestStatusEnum.SUCCESS">
                        <v-icon>mdi-tag-plus-outline</v-icon>
                        {{ abbreviateNumber(balanceDue.saldoAvencerValor) }}
                    </span>
                    <Loader
                        v-if="balanceDueRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                    <v-img
                        src="@/assets/dashboards/widget-img.png"
                        :alt="$tc('dashboards.mainHighlight.title')"
                    ></v-img>
                </v-card>
            </v-col>
            <v-col md="9" id="dashboards-header-grid">
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-account-multiple-outline</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.clientsCount.title') }}</h5>
                        <h6>{{ $tc('dashboards.clientsCount.subtitle') }}</h6>
                    </div>
                    <div
                        class="v-card-content"
                        v-if="
                            clientsCount &&
                            clientsCountRequestStatus === RequestStatusEnum.SUCCESS
                        "
                    >
                        <span>{{ clientsCount.clienteQtd }}</span>
                    </div>
                    <Loader
                        v-if="clientsCountRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-account-multiple-check-outline</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.averageClientExposure.title') }}</h5>
                        <h6>{{ $tc('dashboards.averageClientExposure.subtitle') }}</h6>
                    </div>
                    <div
                        class="v-card-content"
                        v-if="
                            averageClientExposure &&
                            averageClientExposure.length > 0 &&
                            averageClientExposureRequestStatus === RequestStatusEnum.SUCCESS
                        "
                    >
                        <span>
                            <sup>R$</sup>
                            {{ abbreviateNumber(averageClientExposure[0].exposicaoMcMedia) }}
                        </span>
                    </div>
                    <Loader
                        v-if="averageClientExposureRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-trophy-outline</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.top10Exposure.title') }}</h5>
                        <h6>{{ $tc('dashboards.top10Exposure.subtitle') }}</h6>
                    </div>
                    <div
                        class="v-card-content"
                        v-if="
                            top10Exposure &&
                            top10Exposure.length > 0 &&
                            top10ExposureRequestStatus === RequestStatusEnum.SUCCESS
                        "
                    >
                        <span>
                            <sup>R$</sup>
                            {{ abbreviateNumber(top10Exposure[0].exposicaoTop10) }}
                        </span>
                    </div>
                    <Loader
                        v-if="top10ExposureRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-thumb-down-outline</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.overdueBalance.title') }}</h5>
                        <h6>{{ $tc('dashboards.overdueBalance.subtitle') }}</h6>
                    </div>
                    <div
                        class="v-card-content"
                        v-if="
                            overdueBalance &&
                            overdueBalanceRequestStatus === RequestStatusEnum.SUCCESS
                        "
                    >
                        <span>
                            <sup>R$</sup>
                            {{ abbreviateNumber(overdueBalance.emAtrasoValor) }}
                        </span>
                    </div>
                    <Loader
                        v-if="overdueBalanceRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-calendar-remove-outline</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.delayGrowLastDays.title') }}</h5>
                        <table
                            class="v-card-table"
                            v-if="
                                delayGrowLastDays &&
                                delayGrowLastDays.length > 0 &&
                                delayGrowLastDaysRequestStatus === RequestStatusEnum.SUCCESS
                            "
                        >
                            <tbody>
                                <tr v-for="item in [30, 60, 120, 180]" v-bind:key="item">
                                    <td><v-icon>mdi-arrow-right</v-icon> Over {{ item }}</td>
                                    <td>{{ delayGrowLastDays[0][`dashboardIaOver${item}`] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <Loader
                        v-if="delayGrowLastDaysRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
                <v-card flat>
                    <v-icon class="v-icon-header">mdi-currency-usd</v-icon>
                    <div class="pl-3">
                        <h5>{{ $tc('dashboards.availableCash.title') }}</h5>
                        <h6>{{ $tc('dashboards.availableCash.subtitle') }}</h6>
                    </div>
                    <div
                        class="v-card-content"
                        v-if="availableCash && availableCashRequestStatus === RequestStatusEnum.SUCCESS"
                    >
                        <span>
                            <sup>R$</sup>
                            {{ abbreviateNumber(availableCash.caixaDisponivel) }}
                        </span>
                    </div>
                    <Loader
                        v-if="availableCashRequestStatus === RequestStatusEnum.START"
                        class="text-center mt-3"
                    />
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
const abbreviate = require('number-abbreviate');

export default {
    components: {
        Loader,
    },
    data() {
        return {
            RequestStatusEnum,
        };
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            averageClientExposure: 'averageClientExposure',
            averageClientExposureRequestStatus: 'averageClientExposureRequestStatus',
            clientsCount: 'clientsCount',
            clientsCountRequestStatus: 'clientsCountRequestStatus',
            delayGrowLastDays: 'delayGrowLastDays',
            delayGrowLastDaysRequestStatus: 'delayGrowLastDaysRequestStatus',
            balanceDue: 'balanceDue',
            balanceDueRequestStatus: 'balanceDueRequestStatus',
            top10Exposure: 'top10Exposure',
            top10ExposureRequestStatus: 'top10ExposureRequestStatus',
            availableCash: 'availableCash',
            availableCashRequestStatus: 'availableCashRequestStatus',
            overdueBalance: 'overdueBalance',
            overdueBalanceRequestStatus: 'overdueBalanceRequestStatus',
        }),
    },
    methods: {
        ...mapActions('dashboards', {
            fetchAverageClientExposure: 'fetchAverageClientExposure',
            fetchClientsCount: 'fetchClientsCount',
            fetchDelayGrowLastDays: 'fetchDelayGrowLastDays',
            fetchBalanceDue: 'fetchBalanceDue',
            fetchTop10Exposure: 'fetchTop10Exposure',
            fetchAvailableCash: 'fetchAvailableCash',
            fetchOverdueBalance: 'fetchOverdueBalance',
        }),
        abbreviateNumber(value) {
            const numAbbr = new abbreviate(['K', 'Mi', 'Bi', 'Tri']);
            return numAbbr.abbreviate(value, 1);
        },
        load() {
            this.fetchAverageClientExposure({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchClientsCount({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchDelayGrowLastDays({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchBalanceDue({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchTop10Exposure({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchAvailableCash({ idProduct: this.selectedProduct.id, filters: {} });
            this.fetchOverdueBalance({ idProduct: this.selectedProduct.id, filters: {} });
        },
    },
    mounted() {
        this.load();
    },
    watch: {
        selectedProduct() {
            this.load();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../views/Main.scss';
#dashboards-highlights-container {
    padding-left: 0.75rem;
    padding-right: 1.5rem;
}
</style>

<template>
    <section>
        <apexchart
            v-if="upcomingDuesRequestStatus === RequestStatusEnum.SUCCESS"
            height="414px"
            :series="upcomingDues"
            :options="options"
        ></apexchart>
        <Loader
            v-if="upcomingDuesRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />
        <v-alert
            v-if="upcomingDuesRequestStatus.type === RequestStatusEnum.ERROR"
            align="left"
            justify="center"
            dense
            outlined
            type="error"
            class="mt-6 ml-3 mr-3"
        >
            {{ $tc('dashboards.error') }}
            <v-btn depressed color="secondary" class="ml-3" @click="load">
                {{ $tc('defaults.form.reload') }}</v-btn
            >
        </v-alert>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
export default {
    components: {
        Loader,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchUpcomingDues: 'fetchUpcomingDues',
        }),
        load() {
            this.fetchUpcomingDues({});
        },
    },
    computed: {
        ...mapGetters('dashboards', {
            upcomingDues: 'upcomingDues',
            upcomingDuesRequestStatus: 'upcomingDuesRequestStatus',
        }),
    },
    /*
    watch: {
        upcomingDues(newValues) {
            console.log(newValues[0].data);
            console.log(newValues[1].data);

            //categories: ["Abr - 57Mi (23%)", "Mai - 78Mi (43%)", "Jun - 61Mi (13%)", "Jul - 75Mi (53%)", "Ago - 3Mi (23%)", "Set - 70Mi (23%)", "Out - 57Mi (43%)", "Nov - 78Mi (12%)", "Dez - 61Mi (23%)"],
            //const categories = 
        },
    },
    */
    data() {
        return {
            RequestStatusEnum,
            options: {
                legend: {
                    onItemClick: {
                        toggleDataSeries: false,
                    },
                    onItemHover: {
                        highlightDataSeries: false,
                    },
                },
                chart: {
                    type: 'bar',
                    height: 374,
                    stacked: !0,
                    toolbar: {
                        show: !1,
                    },
                    zoom: {
                        enabled: !0,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: !1,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: !1,
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#AAAAAA',
                        },
                    },
                },
                xaxis: {
                    categories: [
                        'Abr - 57Mi (23%)',
                        'Mai - 78Mi (43%)',
                        'Jun - 61Mi (13%)',
                        'Jul - 75Mi (53%)',
                        'Ago - 3Mi (23%)',
                        'Set - 70Mi (23%)',
                        'Out - 57Mi (43%)',
                        'Nov - 78Mi (12%)',
                        'Dez - 61Mi (23%)',
                    ],
                    labels: {
                        style: {
                            colors: '#AAAAAA',
                        },
                    },
                },
                tooltip: {
                    shared: !0,
                    intersect: !1,
                    y: {
                        formatter: function (e) {
                            return void 0 !== e ? e.toFixed(0) + ' Mi' : e;
                        },
                    },
                },
                colors: ['#297453', '#45cb85'],
                fill: {
                    opacity: 1,
                },
            },
        };
    },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Tabs',{ref:"tabEl",staticClass:"mb-6 mt-3",attrs:{"tabs":_vm.tabs,"allowUserChange":true,"showCounter":false},on:{"update":_vm.updateTab}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'topClientsExposure40'),expression:"currentTab === 'topClientsExposure40'"}]},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.top40ClientsExposure,"items":_vm.top40ClientsExposureListResult.items,"hide-default-footer":"","item-key":"clientesQuarentaRaiz"},scopedSlots:_vm._u([{key:"item.clientesQuarentaSaldoAvencer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.formatarDinheiro(item.clientesQuarentaSaldoAvencer) : null)+" ")]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"secondary","length":_vm.top40ClientsExposureListResult.totalPages,"total-visible":7},model:{value:(_vm.top40ClientsExposurePage),callback:function ($$v) {_vm.top40ClientsExposurePage=$$v},expression:"top40ClientsExposurePage"}})]},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Não há dados")])])],2)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'overdueClients'),expression:"currentTab === 'overdueClients'"}]},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.overdueClients,"items":_vm.overdueClientsListResult.items,"hide-default-footer":"","item-key":"clientesAtrasoRaiz"},scopedSlots:_vm._u([{key:"item.clientesAtrasoSaldoVencido",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:"status-chip status-chip-pending",attrs:{"small":""}},[_vm._v(" "+_vm._s(item ? _vm.formatarDinheiro(item.clientesAtrasoSaldoVencido) : null)+" ")])]}},{key:"item.clientesAtrasoDiasAtraso",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:"status-chip status-chip-pending",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$tc('defaults.days', item.clientesAtrasoDiasAtraso))+" ")])]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"secondary","length":_vm.overdueClientsListResult.totalPages,"total-visible":7},model:{value:(_vm.overdueClientsPage),callback:function ($$v) {_vm.overdueClientsPage=$$v},expression:"overdueClientsPage"}})]},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Não há dados")])])],2)],1)],1),(
            _vm.top40ClientsExposureListRequestStatus === _vm.RequestStatusEnum.START ||
            _vm.overdueClientsListRequestStatus === _vm.RequestStatusEnum.START
        )?_c('v-col',[_c('Loader',{staticClass:"text-center mt-3"})],1):_vm._e(),(
            _vm.top40ClientsExposureListRequestStatus.type === _vm.RequestStatusEnum.ERROR ||
            _vm.overdueClientsListRequestStatus === _vm.RequestStatusEnum.ERROR
        )?_c('v-col',[_c('v-alert',{staticClass:"mt-6 ml-3",attrs:{"align":"left","justify":"center","dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$tc('dashboards.tableError'))+" "),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","color":"secondary"},on:{"click":_vm.load}},[_vm._v(" "+_vm._s(_vm.$tc('defaults.form.reload'))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"row",attrs:{"id":"dashboards-highlights-container"}},[_c('v-row',{staticClass:"ml-0"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-card',{staticClass:"v-card-fundwallet",attrs:{"flat":""}},[_c('h3',[_vm._v(_vm._s(_vm.$tc('dashboards.mainHighlight.title')))]),_c('h4',[_vm._v(_vm._s(_vm.$tc('dashboards.mainHighlight.subtitle')))]),(_vm.balanceDue && _vm.balanceDueRequestStatus === _vm.RequestStatusEnum.SUCCESS)?_c('span',[_c('v-icon',[_vm._v("mdi-tag-plus-outline")]),_vm._v(" "+_vm._s(_vm.abbreviateNumber(_vm.balanceDue.saldoAvencerValor))+" ")],1):_vm._e(),(_vm.balanceDueRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e(),_c('v-img',{attrs:{"src":require("@/assets/dashboards/widget-img.png"),"alt":_vm.$tc('dashboards.mainHighlight.title')}})],1)],1),_c('v-col',{attrs:{"md":"9","id":"dashboards-header-grid"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-account-multiple-outline")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.clientsCount.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$tc('dashboards.clientsCount.subtitle')))])]),(
                        _vm.clientsCount &&
                        _vm.clientsCountRequestStatus === _vm.RequestStatusEnum.SUCCESS
                    )?_c('div',{staticClass:"v-card-content"},[_c('span',[_vm._v(_vm._s(_vm.clientsCount.clienteQtd))])]):_vm._e(),(_vm.clientsCountRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-account-multiple-check-outline")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.averageClientExposure.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$tc('dashboards.averageClientExposure.subtitle')))])]),(
                        _vm.averageClientExposure &&
                        _vm.averageClientExposure.length > 0 &&
                        _vm.averageClientExposureRequestStatus === _vm.RequestStatusEnum.SUCCESS
                    )?_c('div',{staticClass:"v-card-content"},[_c('span',[_c('sup',[_vm._v("R$")]),_vm._v(" "+_vm._s(_vm.abbreviateNumber(_vm.averageClientExposure[0].exposicaoMcMedia))+" ")])]):_vm._e(),(_vm.averageClientExposureRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-trophy-outline")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.top10Exposure.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$tc('dashboards.top10Exposure.subtitle')))])]),(
                        _vm.top10Exposure &&
                        _vm.top10Exposure.length > 0 &&
                        _vm.top10ExposureRequestStatus === _vm.RequestStatusEnum.SUCCESS
                    )?_c('div',{staticClass:"v-card-content"},[_c('span',[_c('sup',[_vm._v("R$")]),_vm._v(" "+_vm._s(_vm.abbreviateNumber(_vm.top10Exposure[0].exposicaoTop10))+" ")])]):_vm._e(),(_vm.top10ExposureRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-thumb-down-outline")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.overdueBalance.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$tc('dashboards.overdueBalance.subtitle')))])]),(
                        _vm.overdueBalance &&
                        _vm.overdueBalanceRequestStatus === _vm.RequestStatusEnum.SUCCESS
                    )?_c('div',{staticClass:"v-card-content"},[_c('span',[_c('sup',[_vm._v("R$")]),_vm._v(" "+_vm._s(_vm.abbreviateNumber(_vm.overdueBalance.emAtrasoValor))+" ")])]):_vm._e(),(_vm.overdueBalanceRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-calendar-remove-outline")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.delayGrowLastDays.title')))]),(
                            _vm.delayGrowLastDays &&
                            _vm.delayGrowLastDays.length > 0 &&
                            _vm.delayGrowLastDaysRequestStatus === _vm.RequestStatusEnum.SUCCESS
                        )?_c('table',{staticClass:"v-card-table"},[_c('tbody',_vm._l(([30, 60, 120, 180]),function(item){return _c('tr',{key:item},[_c('td',[_c('v-icon',[_vm._v("mdi-arrow-right")]),_vm._v(" Over "+_vm._s(item))],1),_c('td',[_vm._v(_vm._s(_vm.delayGrowLastDays[0][("dashboardIaOver" + item)]))])])}),0)]):_vm._e()]),(_vm.delayGrowLastDaysRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"v-icon-header"},[_vm._v("mdi-currency-usd")]),_c('div',{staticClass:"pl-3"},[_c('h5',[_vm._v(_vm._s(_vm.$tc('dashboards.availableCash.title')))]),_c('h6',[_vm._v(_vm._s(_vm.$tc('dashboards.availableCash.subtitle')))])]),(_vm.availableCash && _vm.availableCashRequestStatus === _vm.RequestStatusEnum.SUCCESS)?_c('div',{staticClass:"v-card-content"},[_c('span',[_c('sup',[_vm._v("R$")]),_vm._v(" "+_vm._s(_vm.abbreviateNumber(_vm.availableCash.caixaDisponivel))+" ")])]):_vm._e(),(_vm.availableCashRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"text-center mt-3"}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }